import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { Helmet } from "react-helmet";



import HeroBen from "@/assets/backgrounds/hero-ben.png";
import SpotifyLogo from "@/assets/logos/color/spotify-color.png";
import AppleMusicLogo from "@/assets/logos/color/apple-music-color.png";
import TripleJLogo from "@/assets/logos/color/triple-j-color.png";

import BenFireplace from "@/assets/images/ben-fireplace.jpeg";
import BenGuitar from "@/assets/images/ben-guitar.jpeg";
import BenGuitarColor from "@/assets/images/ben-guitar-color.jpeg";
import PalmTreeShadows from "@/assets/images/palm-tree-shadows.jpeg";
import PalmTrees from "@/assets/images/palm-trees.jpeg";
import BenTree from "@/assets/images/ben-tree.jpeg";

import { NavHeader } from "@/features/NavHeader";
import classNames from "classnames";
import { IconLink } from "@/widgets/IconLink";

const Landing = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    strategy: "absolute",
  });


  return (

    <main className="h-screen texture-horz-bg bg-fixed">
      <Helmet>
        <head></head>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6V4Y6RVYWP"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6V4Y6RVYWP');
          `}
        </script>
      </Helmet>
      <NavHeader color="gray" className="absolute w-full" />
      <div className="flex h-full items-center overflow-x-scroll overflow-y-hidden px-12">
        <div className="w-full flex max-h-[750px]">
          <div className="flex w-full max-w-lg h-full flex-col justify-center items-center overflow-x-hidden overflow-y-scroll shrink-0">
            <div className="w-full flex items-center justify-center mb-4 z-10">
              <span className="bg-bm-tan inline-block w-3 h-3 rounded-full mr-4 " />

              <h1 className="text-black text-4xl italic font-goon font-semibold">
                Debut Album{" "}
              </h1>

              <span className="bg-bm-tan inline-block w-3 h-3 rounded-full ml-6" />
            </div>

            <div className="group relative w-full h-[490px] album-bg overflow-hidden">
              <div className="blur hidden" />

              <div className="flex justify-between items-center absolute w-full px-5 pt-5 z-50">
                <h1 className={classNames("font-goon text-4xl", "text-white")}>
                  Begin Again
                </h1>

                <h1 className={classNames("font-goon text-4xl", "text-white")}>
                  Ben Morgan
                </h1>
              </div>
            </div>

            <div className="mt-4 space-x-8">
              <button
                ref={setReferenceElement}
                className="font-goon text-2xl tracking-wider border-2 border-bm-tan bg-bm-light-tan text-black py-3 pb-2.5 px-12 rounded-lg"
                onClick={() => setShowMenu((x) => !x)}
              >
                Stream Now
              </button>
            </div>
          </div>

          <img src={BenGuitar} className=" h-[630px] -ml-8 shrink-0" />

          <div className="ml-16 mt-10 relative shrink-0 flex flex-col">
            <img src={BenFireplace} className="h-[300px] z-10 shrink-0" />

            <div>
              <h2 className="mt-[150px] font-goon text-3xl tracking-widest">
                "Welcome Home Love. So The
              </h2>
              <h2 className="font-goon text-3xl tracking-widest ml-12">
                Story Starts Again"
              </h2>
            </div>
          </div>

          <div className="shrink-0 z-10 ml-12">
            <div className="flex flex-col items-center max-w-lg ml-12 mt-12">
              <h2 className="font-goon text-3xl tracking-widest text-center">
                “No missing the beautiful serenity of this one from Ben. His
                emotional delivery is hard to not buy into”
              </h2>

              <h2 className="font-old w-fit italic text-center tracking-widest border-t-2 border-t-gray-400 mt-3 pt-3">
                - Dave Ruby Howe (Triple J)
              </h2>
            </div>

            <div className="relative mt-24 ml-[35px]">
              <img
                src={BenGuitarColor}
                className="h-[200px] absolute z-50 -top-12 -left-[180px]"
              />
              <img src={PalmTreeShadows} className="h-[350px] relative z-10" />
            </div>
          </div>

          <div className="shrink-0 ml-24 flex flex-col justify-between pr-24 mt-8">
            <div className="shrink-0 relative">
              <img src={BenTree} className="w-[250px] shrink-0 relative z-20" />
              <img
                src={PalmTrees}
                className="w-[240px] shrink-0 absolute top-[50%] left-[25%] z-10"
              />
            </div>

            <div className="flex flex-col items-center max-w-xl -ml-72 mb-12">
              <h2 className="font-goon text-3xl tracking-widest text-center">
                "Beautiful fingerpicking and a folky vocal that has a bit of
                oomph to it too"
              </h2>

              <h2 className="font-old w-fit italic text-lg text-center tracking-widest border-t-2 border-t-gray-400 mt-3 pt-3">
                - Tommy Faith (Triple J)
              </h2>
            </div>
          </div>
        </div>
      </div>

      {showMenu && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className="bg-white flex flex-col items-center mb-2 border-b-8 border-bm-tan rounded-sm overflow-hidden"
          {...attributes.popper}
        >
          <a
            href="https://open.spotify.com/artist/0BGZx1OzVJ5uwHK4Ke5mNr"
            className="p-3 px-6 border-b-2 border-bm-tan hover:bg-stone-100 h-20"
          >
            <img
              src={SpotifyLogo}
              className={classNames("w-12 h-12", "shrink-0")}
            />
          </a>

          <a
            href="https://music.apple.com/au/artist/ben-morgan/1552341609"
            className="p-3 px-6 border-b-2 border-bm-tan hover:bg-stone-100 h-20"
          >
            <img
              src={AppleMusicLogo}
              className={classNames("w-12 h-12", "shrink-0")}
            />
          </a>

          <a
            href="https://www.abc.net.au/triplejunearthed/artist/ben-morgan/"
            className="p-3 px-6 hover:bg-stone-100 -ml-2.5"
          >
            <img
              src={TripleJLogo}
              className={classNames("w-12 h-12", "shrink-0")}
            />
          </a>
        </div>
      )}
    </main>
  );
};

export default Landing;
